import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


console.log('Env : ',  process.env);
let paramNouveauLogo = process.env.VUE_APP_NOUVEAU_LOGO;
if (paramNouveauLogo == "true") {
    paramNouveauLogo = true; // on stocke la hauteur du bandeau de connexion
}
else {
    paramNouveauLogo = false;
}
let paramConnexionFixed = process.env.VUE_APP_CONNEXION_FIXED;
if (paramConnexionFixed == "true") {
    paramConnexionFixed = 80; // on stocke la hauteur du bandeau de connexion
}
else {
    paramConnexionFixed = false;
}
let paramDevScroll = process.env.VUE_APP_SCROLL;
if (paramDevScroll == "true") {
    paramDevScroll = true;
}
else {
    paramDevScroll = false;
}
let paramBandeauComm = process.env.VUE_APP_BANDEAU_COMM
if (paramBandeauComm == "true") {
    paramBandeauComm = true;
}
else {
    paramBandeauComm = false;
}
let paramPRO = process.env.VUE_APP_PRO;
let markOrientest = process.env.VUE_APP_MARK_ORIENTEST;
let markLorfolio = process.env.VUE_APP_MARK_LORFOLIO;
let version = process.env.VUE_APP_VERSION;
if (paramPRO == "false") {
    paramPRO = false;
}
else {
    if ((paramPRO == "true")) {
        paramPRO = true;
    }
}
if ((markOrientest == "false") && (markLorfolio == "true")) {
    markOrientest = false;
}
else {
    markOrientest = true;
}
if (markLorfolio == "true") {
    markLorfolio = true;
}
else {
    markLorfolio = false;
}
//const paramPRO = false;

console.log('paramPRO : ', paramPRO)
console.log('markOrientest : ', markOrientest)
console.log('version : ', version)

// paramétrage lorfolioPRO ou lorfolio.fr
// ***************************************************************************************************************************************************************************
const isProdOk = isProd();
if (paramPRO) {
    var siteId = 12;
    if (markOrientest) {
        siteId = 15;
        if (isProdOk) { // on ne charge Matomo RGE qu'en Prod - et pas en preRender par exemple
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '/matomoOrientEstPro.js';
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    }
}
else {
    var siteId = 6;
    if (markOrientest) {
        siteId = 14;
        if (isProdOk) { // on ne charge Matomo RGE qu'en Prod - et pas en preRender par exemple
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = '/matomoMonOrientEst.js';
            document.getElementsByTagName('head')[0].appendChild(script);
        }
/**/
        else {
            if (!isLocal()) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = '/matomoMonOrientEstAddeo.js';
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        }
/**/
    }
}
        function isLocal() {
            var leDomaine = window.location.hostname;
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                return(true);
            }
            return(false);
        }
        function isProd() {
            var leDomaine = window.location.hostname;
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                return(false);
            }
            if ( leDomaine.indexOf("grandtest") >= 0 ) {
                return(false);
            }
            if ( leDomaine.indexOf("preprod") >= 0 ) {
                return(false);
            }
            return(true);
        }

        function urlDomaine() {
            if (paramPRO) {
                return("https://www.orientest.pro");
            }
            else {
                return("https://www.monorientest.fr");
            }
/*
            var leDomaine = window.location.hostname;
            if ( leDomaine.indexOf("localhost") >= 0 ) {
                if (paramPRO) {
                    return("https://portailfoliopro.grandtest.addeo.ovh");
                }
                else {
                    return("https://portailfolio.grandtest.addeo.ovh");
                }
            }

            if ( leDomaine.indexOf("grandtest") > 0 ) {
                return("https://"+leDomaine);
            }
            else {
                return("https://"+leDomaine);
            }
*/
        }


// le router ... enfin ...
// ***************************************************************************************************************************************************************************
// penser à déclarer les URLs dans vue.config.js (pour le prerender)
Vue.use(VueRouter)

const routesFR = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/accueil',
    meta: {
      title: 'Lorfolio, toujours préparé pour mes choix professionnels en Grand Est',
      metaTags: [ {
          name: 'description',
          content: 'Quelle que soit ma situation, je bénéficie de tous les services utiles pour retracer mon parcours, identifier mes compétences, élaborer mon projet professionnel, créer des CV ou des sites web.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Lorfolio, toujours préparé pour mes choix professionnels en Grand Est'},
        {name: 'twitter:description', content: "Quelle que soit ma situation, je bénéficie de tous les services utiles pour retracer mon parcours, identifier mes compétences, élaborer mon projet professionnel, créer des CV ou des sites web."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfolio2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Lorfolio, toujours préparé pour mes choix professionnels en Grand Est'},
        {property: 'og:site_name', content: 'Lorfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfolio.png')},
        {property: 'og:description', content: "Quelle que soit ma situation, je bénéficie de tous les services utiles pour retracer mon parcours, identifier mes compétences, élaborer mon projet professionnel, créer des CV ou des sites web."}
        ]
    }
  },
  {
    path: '/partenaires',
    name: 'Partenaires',
    alias: '/partenaires',
    component: () => import( '../views/Partenaires.vue'),
    meta: {
      title: 'Les partenaires de Lorfolio',
      metaTags: [ {
          name: 'description',
          content: 'J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Lorfolio.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Les partenaires de Lorfolio'},
        {name: 'twitter:description', content: "J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Lorfolio."},
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfolio2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/partenaires'},
        {property: 'og:title', content: 'Les partenaires de Lorfolio'},
        {property: 'og:site_name', content: 'Lorfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfolio.png')},
        {property: 'og:description', content: "J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Lorfolio."}
        ]
    }
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    alias: '/mentionsLegales',
    component: () => import('../views/MentionsLegales.vue'),
    meta: {
      title: 'Lorfolio est un service édité par la région Grand Est',
      metaTags: [ {
          name: 'description',
          content: 'Vous disposez d’un compte personnel, gratuit et sécurisé.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Lorfolio est un service édité par la région Grand Est'},
        {name: 'twitter:description', content: "Vous disposez d’un compte personnel, gratuit et sécurisé."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfolio2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/mentions-legales'},
        {property: 'og:title', content: 'Lorfolio est un service édité par la région Grand Est'},
        {property: 'og:site_name', content: 'Lorfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfolio.png')},
        {property: 'og:description', content: "Vous disposez d’un compte personnel, gratuit et sécurisé."}
        ]
    }
  },
  {
    path: '/contact-lorfolio',
    name: 'Contact',
    pere:':pere',
    component: () => import( '../views/Contact.vue'),
    meta: {
      title: 'Contactez l’équipe Lorfolio',
      metaTags: [ {
          name: 'description',
          content: 'Faites nous connaître vos questions, vos suggestions, vos remarques.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe Lorfolio'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfolio2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-lorfolio'},
        {property: 'og:title', content: 'Contactez l’équipe Lorfolio'},
        {property: 'og:site_name', content: 'Lorfolio'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfolio.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },
  {
    path: '*',
    name: 'Page inconnue',
    component: () => import('../views/404.vue')
  }
];

const routesPro = [
  {
    path: '/',
    name: 'Pro',
    alias: '/pro',
    component: () => import('../views/Pro.vue'),
    meta: {
      title: 'Lorfolio PRO, faciliter les choix professionnels de vos publics',
      metaTags: [ {
          name: 'description',
          content: "Vous êtes professionnel de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez des outils pros de Lorfolio pour accompagner vos publics, y compris à distance."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Lorfolio Pro, faciliter les choix professionnels de vos publics'},
        {name: 'twitter:description', content: "Vous êtes professionnel de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez des outils pros de Lorfolio pour accompagner vos publics, y compris à distance."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Lorfolio Pro, faciliter les choix professionnels de vos publics'},
        {property: 'og:site_name', content: 'LorfolioPro'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Vous êtes professionnel de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez des outils pros de Lorfolio pour accompagner vos publics, y compris à distance."}
        ]
    }
  },
  {
    path: '/partenaires-pro',
    name: 'Partenaires',
    alias: '/partenairesPro',
    // route level code-splitting
    // this generates a separate chunk (partenaires.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Partenaires.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Les partenaires de Lorfolio',
      metaTags: [ {
          name: 'description',
          content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Les partenaires de Lorfolio'},
        {name: 'twitter:description', content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/partenaires-pro'},
        {property: 'og:title', content: 'Les partenaires de Lorfolio'},
        {property: 'og:site_name', content: 'LorfolioPro'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."}
        ]
    }
  },
  {
    path: '/mentions-legales-pro',
    name: 'MentionsLegales',
    alias: '/mentionsLegalesPro',
    component: () => import('../views/MentionsLegales.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Lorfolio est un service édité par la Région Grand Est',
      metaTags: [ {
          name: 'description',
          content: "Vous disposez d’un compte PRO, gratuit et sécurisé."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Lorfolio est un service édité par la région Grand Est'},
        {name: 'twitter:description', content: "Vous disposez d’un compte PRO, gratuit et sécurisé."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/mentions-legales-pro'},
        {property: 'og:title', content: 'Lorfolio est un service édité par la région Grand Est'},
        {property: 'og:site_name', content: 'LorfolioPro'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Vous disposez d’un compte PRO, gratuit et sécurisé."}
        ]
    }
  },
  {
    path: '/contact-lorfolio-pro',
    name: 'ContactPro',
    pere:':pere',
    alias: '/ContactPro',
    component: () => import( '../views/Contact.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Contactez l’équipe Lorfolio',
      metaTags: [ {
          name: 'description',
          content: "Faites nous connaître vos questions, vos suggestions, vos remarques."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe Lorfolio'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-lorfolio-pro'},
        {property: 'og:title', content: 'Contactez l’équipe Lorfolio'},
        {property: 'og:site_name', content: 'LorfolioPro'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },
  {
    path: '/rencontres-pro',
    name: 'AgendaPro',
    alias: '/agenda-pro',
    component: () => import('../views/AgendaPro.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Explorez la face pro de Lorfolio',
      metaTags: [ {
          name: 'description',
          content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros de Lorfolio."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Explorez la face pro de Lorfolio'},
        {name: 'twitter:description', content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros de Lorfolio."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/lorfoliopro2.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/rencontres-pro'},
        {property: 'og:title', content: 'Explorez la face pro de Lorfolio'},
        {property: 'og:site_name', content: 'LorfolioPro'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/lorfoliopro.png')},
        {property: 'og:description', content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros de Lorfolio."}
/**/
        ]
    }
  },

  {
    path: '/404',
    name: 'Page inconnue',
    component: () => import('../views/404Pro.vue')
  },
  {
    path: '*',
    name: 'Page inconnue',
    component: () => import('../views/404Pro.vue')
  },

];



const routesMonOrientest = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/accueil',
    meta: {
      title: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels',
      metaTags: [ {
          name: 'description',
          content: 'Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV...'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels'},
        {name: 'twitter:description', content: "Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV..."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels'},
        {property: 'og:site_name', content: 'Mon Orient’Est'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV..."}
        ]
    }
  },
  {
    path: '/partenaires',
    name: 'Partenaires',
    alias: '/partenaires',
    component: () => import( '../views/Partenaires.vue'),
    meta: {
      title: "Mon Orient'Est : Partenaires",
      metaTags: [ {
          name: 'description',
          content: "J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Mon Orient'Est."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: "Mon Orient'Est : Partenaires"},
        {name: 'twitter:description', content: "J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Mon Orient'Est."},
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/partenaires'},
        {property: 'og:title', content: "Mon Orient'Est : Partenaires"},
        {property: 'og:site_name', content: 'Mon Orient’Est'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "J’identifie les structures qui peuvent m’accompagner pour préparer mes choix professionnels avec Mon Orient'Est."}
        ]
    }
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    alias: '/mentionsLegales',
    component: () => import('../views/MentionsLegales.vue'),
    meta: {
      title: "Mon Orient'Est : Mentions légales",
      metaTags: [ {
          name: 'description',
          content: "Mon Orient'Est est un service édité par la région Grand Est - Vous disposez d’un compte personnel, gratuit et sécurisé."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: "Mon Orient'Est : Mentions légales"},
        {name: 'twitter:description', content: "Mon Orient'Est est un service édité par la région Grand Est - Vous disposez d’un compte personnel, gratuit et sécurisé."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/mentions-legales'},
        {property: 'og:title', content: "Mon Orient'Est : Mentions légales"},
        {property: 'og:site_name', content: 'Mon Orient’Est'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Mon Orient'Est est un service édité par la région Grand Est - Vous disposez d’un compte personnel, gratuit et sécurisé."}
        ]
    }
  },
  {
    path: '/contact-mon-orientest',
    name: 'Contact',
    pere:':pere',
    component: () => import( '../views/Contact.vue'),
    meta: {
      title: 'Contactez l’équipe Orient’Est',
      metaTags: [ {
          name: 'description',
          content: 'Faites nous connaître vos questions, vos suggestions, vos remarques.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe Orient’Est'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-mon-orientest'},
        {property: 'og:title', content: 'Contactez l’équipe Orient’Est'},
        {property: 'og:site_name', content: 'Mon Orient’Est'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },
  {
    path: '/contact-orientest',
    name: 'ContactOrientest',
    pere:':pere',
    component: () => import( '../views/ContactOrientestFr.vue'),
    meta: {
      title: 'Contactez l’équipe Orient’Est',
      metaTags: [ {
          name: 'description',
          content: 'Faites nous connaître vos questions, vos suggestions, vos remarques.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe Orient’Est'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-orientest'},
        {property: 'og:title', content: 'Contactez l’équipe Orient’Est'},
        {property: 'og:site_name', content: "Orient'Est"},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },
  {
    path: '/contact-agenda-public',
    name: 'ContactAgendaPublic',
    pere:':pere',
    component: () => import( '../views/ContactAgendaPublic.vue'),
    meta: {
      title: 'Proposer un événement en Grand Est',
      metaTags: [ {
          name: 'description',
          content: "Les porteurs, organisateurs ou structures de l’orientation-formation-emploi-insertion peuvent proposer des événements à venir pour l’agenda de l'orientation"
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Proposer un événement en Grand Est'},
        {name: 'twitter:description', content: "Les porteurs, organisateurs ou structures de l’orientation-formation-emploi-insertion peuvent proposer des événements à venir pour l’agenda de l'orientation"},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-orientest'},
        {property: 'og:title', content: 'Proposer un événement en Grand Est'},
        {property: 'og:site_name', content: "Orient'Est"},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Les porteurs, organisateurs ou structures de l’orientation-formation-emploi-insertion peuvent proposer des événements à venir pour l’agenda de l'orientation"}
        ]
    }
  },
  {
    path: '/contact-romain',
    name: 'ContactRomain',
    pere:':pere',
    component: () => import( '../views/ContactOrientestRomain.vue'),
    meta: {
      title: 'Contactez l’équipe OrientEst',
      metaTags: [ {
          name: 'description',
          content: 'Faites nous connaître vos questions, vos suggestions, vos remarques.'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe OrientEst'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientest.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-orientest'},
        {property: 'og:title', content: 'Contactez l’équipe OrientEst'},
        {property: 'og:site_name', content: 'OrientEst'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientest.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },

  {
    path: '/404',
    name: 'Page inconnue 404',
    component: () => import('../views/404.vue'),
    meta: {
      title: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels',
      metaTags: [ {
          name: 'description',
          content: 'Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV...'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels'},
        {name: 'twitter:description', content: "Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV..."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels'},
        {property: 'og:site_name', content: 'Mon Orient’Est'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV..."}
        ]
    }
  },
  {
    path: '*',
    name: 'Page inconnue',
/*    alias: '/404',*/
    component: () => import('../views/404.vue'),
    meta: {
      title: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels',
      metaTags: [ {
          name: 'description',
          content: 'Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV...'
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels'},
        {name: 'twitter:description', content: "Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV..."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Mon Orient’Est, Toujours préparé pour mes choix professionnels'},
        {property: 'og:site_name', content: 'Mon Orient’Est'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Le compte personnel offert par la région Grand Est pour retracer ses acquis, décrire ses compétences, préparer son projet professionnel, créer des CV..."}
        ]
    }
  }
];

const routesOrientestPro = [
  {
    path: '/',
    name: 'Pro',
    alias: '/pro',
    component: () => import('../views/Pro.vue'),
    meta: {
      title: 'Orient’Est PRO, Outiller vos pratiques',
      metaTags: [ {
          name: 'description',
          content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Orient’Est Pro, faciliter les choix professionnels de vos publics'},
        {name: 'twitter:description', content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestpro.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Orient’Est PRO, Outiller vos pratiques'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"}
        ]
    }
  },
  {
    path: '/partenaires-pro',
    name: 'Partenaires',
    alias: '/partenairesPro',
    // route level code-splitting
    // this generates a separate chunk (partenaires.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Partenaires.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Orient’Est PRO : Partenaires',
      metaTags: [ {
          name: 'description',
          content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Orient’Est PRO : Partenaires'},
        {name: 'twitter:description', content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestProTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/partenaires-pro'},
        {property: 'og:title', content: 'Orient’Est PRO : Partenaires'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Découvrez le réseau du Service Public Régional d’Orientation en Grand Est."}
        ]
    }
  },
  {
    path: '/mentions-legales-pro',
    name: 'MentionsLegales',
    alias: '/mentionsLegalesPro',
    component: () => import('../views/MentionsLegales.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Orient’Est PRO : Mentions légales',
      metaTags: [ {
          name: 'description',
          content: "Orient’Est PRO est un service édité par la Région Grand Est. Vous disposez d’un compte PRO, gratuit et sécurisé."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Orient’Est PRO : Mentions légales'},
        {name: 'twitter:description', content: "Orient’Est PRO est un service édité par la Région Grand Est. Vous disposez d’un compte PRO, gratuit et sécurisé."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestProTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/mentions-legales-pro'},
        {property: 'og:title', content: 'Orient’Est PRO : Mentions légales'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Orient’Est PRO est un service édité par la Région Grand Est. Vous disposez d’un compte PRO, gratuit et sécurisé."}
        ]
    }
  },
  {
    path: '/contact-orientest-pro',
    name: 'ContactPro',
    pere:':pere',
    alias: '/ContactPro',
    component: () => import( '../views/Contact.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Contactez l’équipe Orient’Est',
      metaTags: [ {
          name: 'description',
          content: "Faites nous connaître vos questions, vos suggestions, vos remarques."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe Orient’Est'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestProTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-orientest-pro'},
        {property: 'og:title', content: 'Contactez l’équipe Orient’Est'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },
  {
    path: '/contact-orientest-pros',
    name: 'ContactPros',
    pere:':pere',
    alias: '/ContactPros',
    component: () => import( '../views/ContactOrientestPro.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Contactez l’équipe Orient’Est',
      metaTags: [ {
          name: 'description',
          content: "Faites nous connaître vos questions, vos suggestions, vos remarques."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Contactez l’équipe Orient’Est'},
        {name: 'twitter:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestProTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/contact-orientest-pros'},
        {property: 'og:title', content: 'Contactez l’équipe Orient’Est'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Faites nous connaître vos questions, vos suggestions, vos remarques."}
        ]
    }
  },
  {
    path: '/rencontres-pro',
    name: 'AgendaPro',
    alias: '/agenda-pro',
    component: () => import('../views/AgendaPro.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Explorez la face pro d\'Orient’Est',
      metaTags: [ {
          name: 'description',
          content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros d'Orient’Est."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Explorez la face pro d\'Orient’Est'},
        {name: 'twitter:description', content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros d'Orient’Est."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestProTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/rencontres-pro'},
        {property: 'og:title', content: 'Explorez la face pro d\'Orient’Est'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Consultez l’agenda des séances de découverte, des formations de prise en main, des sessions d’échanges entre pros d\'Orient’Est."}
/**/
        ]
    }
  },
  {
    path: '/professionnalisation',
    name: 'Professionnalisation',
    alias: '/professionnalisation-programme',
    component: () => import('../views/Professionnalisation.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Explorez la face pro d\'Orient’Est',
      metaTags: [ {
          name: 'description',
          content: "Programme de professionnalisation Orient’Est."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Programme de professionnalisation Orient’Est'},
        {name: 'twitter:description', content: "Consultez l’agenda du programme de professionnalisation Orient’Est."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestProTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/professionnalisation'},
        {property: 'og:title', content: 'Programme de professionnalisation Orient’Est.'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Consultez l’agenda du programme de professionnalisation Orient’Est."}
/**/
        ]
    }
  },
  {
    path: '/professionnalisation2',
    name: 'Professionnalisation2',
    alias: '/professionnalisation2-programme',
    component: () => import('../views/Professionnalisation2.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Explorez la face pro d\'Orient’Est',
      metaTags: [ {
          name: 'description',
          content: "Programme de professionnalisation Orient’Est."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Programme de professionnalisation Orient’Est'},
        {name: 'twitter:description', content: "Consultez l’agenda du programme de professionnalisation Orient’Est."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestProTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/professionnalisation'},
        {property: 'og:title', content: 'Programme de professionnalisation Orient’Est.'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Consultez l’agenda du programme de professionnalisation Orient’Est."}
/**/
        ]
    }
  },
/**/
  {
    path: '/professionnalisation3',
    name: 'Professionnalisation3',
    alias: '/professionnalisation3-programme',
    component: () => import('../views/Professionnalisation3.vue'),
    props: { sitePro: true},
    meta: {
      title: 'Explorez la face pro d\'Orient’Est',
      metaTags: [ {
          name: 'description',
          content: "Programme de professionnalisation Orient’Est."
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Programme de professionnalisation Orient’Est'},
        {name: 'twitter:description', content: "Consultez l’agenda du programme de professionnalisation Orient’Est."},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestProTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/professionnalisation'},
        {property: 'og:title', content: 'Programme de professionnalisation Orient’Est.'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Consultez l’agenda du programme de professionnalisation Orient’Est."}
        ]
    }
  },/**/
  {
    path: '/ambassadeurs',
    name: 'Ambassadeurs',
    pere:':pere',
    component: () => import( '../views/Ambassadeurs.vue'),
    meta: {
      title: "Les Ambassadeurs-Ambassadrices Métiers Orient'Est",
      metaTags: [ {
          name: 'description',
          content: "Partagez avec passion votre métier"
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: "Les Ambassadeurs-Ambassadrices Métiers Orient'Est"},
        {name: 'twitter:description', content: "Partagez avec passion votre métier"},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/ambassadeurs'},
        {property: 'og:title', content: "Les Ambassadeurs-Ambassadrices Métiers Orient'Est"},
        {property: 'og:site_name', content: "Orient'Est"},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Partagez avec passion votre métier"}
        ]
    }
  },
  {
    path: '/bao',
    name: 'BAO',
    pere:':pere',
    component: () => import( '../views/Bao.vue'),
    meta: {
      title: "La boîte à outils Orient'Est",
      metaTags: [ {
          name: 'description',
          content: "Faciliter l'accompagnement à l'orientation  scolaire et professionnelle"
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: "La boîte à outils Orient'Est"},
        {name: 'twitter:description', content: "Faciliter l'accompagnement à l'orientation  scolaire et professionnelle"},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestTW.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()+'/bao'},
        {property: 'og:title', content: "La boîte à outils Orient'Est"},
        {property: 'og:site_name', content: "Orient'Est"},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestOG.png')},
        {property: 'og:description', content: "Faciliter l'accompagnement à l'orientation  scolaire et professionnelle"}
        ]
    }
  },
  {
    path: '/404',
    name: 'Page inconnue 404',
    component: () => import('../views/404Pro.vue'),
    meta: {
      title: 'Orient’Est PRO, Outiller vos pratiques',
      metaTags: [ {
          name: 'description',
          content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Orient’Est Pro, faciliter les choix professionnels de vos publics'},
        {name: 'twitter:description', content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestpro.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Orient’Est PRO, Outiller vos pratiques'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"}
        ]
    }
  },
  {
    path: '*',
    name: 'Page inconnue',
    component: () => import('../views/404Pro.vue'),
    meta: {
      title: 'Orient’Est PRO, Outiller vos pratiques',
      metaTags: [ {
          name: 'description',
          content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"
        },
        // Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: 'Orient’Est Pro, faciliter les choix professionnels de vos publics'},
        {name: 'twitter:description', content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"},
        // image must be an absolute path
        {name: 'twitter:image', content: urlDomaine() + ('/img/orientestpro.png')},
        // Facebook OpenGraph
        {property: 'og:url', content: urlDomaine()},
        {property: 'og:title', content: 'Orient’Est PRO, Outiller vos pratiques'},
        {property: 'og:site_name', content: 'Orient’Est PRO'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  urlDomaine() + ('/img/orientestProOG.png')},
        {property: 'og:description', content: "Pro de la formation, de l'orientation, de l'emploi ou de l'insertion en Grand Est ? Profitez d'Orient’Est PRO pour accompagner vos publics, y compris à distance"}
        ]
    }
  }
];


if (markOrientest || !markLorfolio) {
    if (paramPRO) {
        console.log('paramPRO PRO : ', paramPRO)
        var routes = routesOrientestPro;
    }
    else {
        console.log('paramPRO PAS PRO : ', paramPRO)
        var routes = routesMonOrientest;
    }
}
else {
    if (paramPRO) {
        console.log('paramPRO PRO : ', paramPRO)
        var routes = routesPro;
    }
    else {
        console.log('paramPRO PAS PRO : ', paramPRO)
        var routes = routesFR;
    }
}



const router = new VueRouter({
    paramPRO:paramPRO,
    paramBandeauComm:paramBandeauComm,
    markOrientest:markOrientest,
    paramDevScroll:paramDevScroll,
    paramConnexionFixed:paramConnexionFixed,
    paramNouveauLogo:paramNouveauLogo,
    version:version,
    siteId:siteId,
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return { x: 0, y: 0 };
  }
})


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

//        /*this.myTimeout = */setTimeout(toTop, 250);

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();

});

router.afterEach((to, from, failure) => {
  if (!failure) pushMatomo(to.fullPath, {title: to.title})
});

function toTop() {
 //   if (this.myTimeout) {
        //clearTimeout(this.myTimeout);
        window.scroll(0, 0);
//        }
}

function pushMatomo(url, { title }) {
  if (typeof _paq === "undefined") {
    return;
  }

  _paq.push(['setCustomUrl', url]);
  _paq.push(['setDocumentTitle', title]);
  _paq.push(['trackPageView']);
}


export default router

